import { GetterTree } from 'vuex'
import { diff } from '@/utils/useSimpleTransformations'
import { TemplatesStateInterface } from '@/models/store/TemplatesState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import { TemplateInterface } from '@/models/templates/Template.interface'
import { GroupedTemplatesMapInterface } from '@/models/templates/GroupedTemplatesMap.interface'
import { TemplateVariant } from '@/models/templates/VariantsInfo.interface'

const getters: GetterTree<TemplatesStateInterface, RootStateInterface> = {
  templatesGroupedBySlug(state: TemplatesStateInterface) {
    const result = state.templates.reduce(
      (groups, template: TemplateInterface) => {
        if (!template.slug) {
          return groups
        }

        const group: TemplateInterface[] = groups[template.slug] || []
        group.push(template)
        groups[template.slug] = group

        return groups
      },
      {} as GroupedTemplatesMapInterface
    )

    return result
  },
  removingVariantsAllowed(
    state: TemplatesStateInterface,
    getters: { savedTemplateVariantsAmount: number }
  ) {
    return getters.savedTemplateVariantsAmount > 1
  },
  savedTemplateVariantsAmount(state: TemplatesStateInterface) {
    let count = 0
    const { available, new: newInfo } = state.variantsInfo

    available.forEach((templateVariant) => {
      const { language, tonality, country } = templateVariant

      const isIncluded = ({ language: l, tonality: t, country: c }) =>
        l === language && t === tonality && c === country

      if (!newInfo.some(isIncluded)) count++
    })

    return count
  },
  availableLanguages(state: TemplatesStateInterface) {
    const languages = state.variantsInfo.available.map(
      ({ language }) => language
    )
    return [...new Set(languages)]
  },
  availableTonalities(state: TemplatesStateInterface) {
    const language = state.template.language!

    const tonalities = state.variantsInfo.available
      .filter(({ language: l }) => l === language)
      .map(({ tonality: t }) => t)

    return [...new Set(tonalities)]
  },
  availableCountries(state: TemplatesStateInterface) {
    const { language, tonality } = state.template

    const countries = state.variantsInfo.available
      .filter(
        ({ language: l, tonality: t }) => l === language && t === tonality
      )
      .map(({ country: c }) => c)

    return [...new Set(countries)]
  },
  newLanguages(
    state: TemplatesStateInterface,
    getters: { availableLanguages: string[] },
    rootState: RootStateInterface
  ) {
    return diff(rootState.shared.config.languages, getters.availableLanguages)
  },
  newTonalities(
    state: TemplatesStateInterface,
    getters: { availableTonalities: string[] },
    rootState: RootStateInterface
  ) {
    return diff(rootState.shared.config.tonalities, getters.availableTonalities)
  },
  newCountries(
    state: TemplatesStateInterface,
    getters: { availableCountries: string[] },
    rootState: RootStateInterface
  ) {
    return diff(rootState.shared.config.countries, getters.availableCountries)
  },
  newlyAddedCountries(state: TemplatesStateInterface) {
    const { language, tonality } = state.template

    const countries = state.variantsInfo.new
      .filter(
        ({ language: l, tonality: t }) => l === language && t === tonality
      )
      .map(({ country: c }) => c)

    return [...new Set(countries)]
  },
  currentTemplateVariant(state: TemplatesStateInterface) {
    const { language, tonality, country } = state.template
    return { language, tonality, country } as TemplateVariant
  }
}

export default getters
