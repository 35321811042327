import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { userService } from '@/services/User.service'
import ListTemplates from '@/pages/ListTemplates.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: ListTemplates,
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/templates',
    name: 'index_templates',
    component: ListTemplates,
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/templates/:slug',
    name: 'show_template',
    component: () => import('@/pages/ShowTemplate.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/template-versions/:id',
    name: 'show_template_version',
    component: () => import('@/pages/ShowTemplate.vue'),
    props: true
  },
  {
    path: '/templates/:slug/edit',
    name: 'edit_template',
    component: () => import('@/pages/EditTemplate.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/templates/new',
    name: 'new_template',
    component: () => import('@/pages/EditTemplate.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/TheLogin.vue'),
    props: (route) => route.query
  },
  {
    path: '/manual-send',
    name: 'manual_send',
    component: () => import('@/pages/ManualSend.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/surveys/new',
    name: 'new_survey',
    component: () => import('@/pages/EditSurvey.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  },
  {
    path: '/surveys/:id',
    name: 'show_survey',
    component: () => import('@/pages/ShowSurvey.vue'),
    props: true
  },
  {
    path: '/surveys/:id/edit',
    name: 'edit_survey',
    component: () => import('@/pages/EditSurvey.vue'),
    props: true
  },
  {
    path: '/surveys',
    name: 'list_surveys',
    component: () => import('@/pages/ListSurveys.vue'),
    props: (route) => Object.assign({}, route.query, route.params)
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (process.env.VUE_APP_AUTH_DISABLED) return
  if (!userService.isLoggedIn() && to.name !== 'login') return { name: 'login' }
})

export default router
