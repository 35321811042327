import { MutationTree } from 'vuex'
import { ImagesStateInterface } from '@/models/store/ImagesState.interface'
import { ImageInterface } from '@/models/images/Image.interface'

const mutations: MutationTree<ImagesStateInterface> = {
  loadingImages(state: ImagesStateInterface, { setLoadingFlag = true }) {
    if (setLoadingFlag) state.loading = true
    state.images = []
  },
  loadedImages(state: ImagesStateInterface, images: ImageInterface[]) {
    state.images = images
    state.loading = false
  },
  uploadedImage(state: ImagesStateInterface, image: ImageInterface) {
    state.images = [image, ...state.images]
  }
}

export default mutations
