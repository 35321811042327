import { Module } from 'vuex'
import { PaginationStateInterface } from '@/models/store/PaginationState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

export const defaultState = () => {
  return { page: 1, perPage: 25, totalCount: null }
}

const paginationModule: Module<PaginationStateInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    ...defaultState()
  } as PaginationStateInterface,
  actions: {
    resetState({ commit }) {
      commit('resetState')
    },
    updateTotalCount({ commit }, totalCount: number) {
      commit('updateTotalCount', totalCount)
    },
    updatePage({ commit }, page: number) {
      commit('updatePage', page)
    },
    updatePagination(
      { commit },
      { page, perPage }: { page?: number; perPage?: number }
    ) {
      commit('updatePagination', { page, perPage })
    }
  },
  mutations: {
    resetState(state: PaginationStateInterface) {
      Object.assign(state, defaultState())
    },
    updateTotalCount(state: PaginationStateInterface, totalCount: number) {
      state.totalCount = totalCount
    },
    updatePage(state: PaginationStateInterface, page: number) {
      state.page = page
    },
    updatePagination(
      state: PaginationStateInterface,
      { page, perPage }: { page?: number; perPage?: number }
    ) {
      if (typeof page !== 'undefined') Object.assign(state, { page })
      if (typeof perPage !== 'undefined') Object.assign(state, { perPage })
    }
  }
}

export default paginationModule
