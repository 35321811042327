const clearedPreviewFields = () => {
  return {
    to: '',
    subject: '',
    from: '',
    html: '',
    hsmParams: [],
    attachments: []
  }
}

export { clearedPreviewFields }
