import { ActionTree } from 'vuex'
import {
  fetchConfig,
  fetchAppVersion as fetchAppVersionRequest
} from '@/api_client'
import { userService } from '@/services/User.service'
import { SharedStateInterface } from '@/models/store/SharedState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import { ConfigInterface } from '@/models/Config.interface'

const actions: ActionTree<SharedStateInterface, RootStateInterface> = {
  async fetchAppVersion({ commit }) {
    const { version } = (await fetchAppVersionRequest()) as { version: string }
    commit('loadedAppVersion', version)
  },
  async loadConfig({ commit }) {
    const { configuration: data } = await fetchConfig()
    const config: ConfigInterface = data

    commit('loadedConfig', config)
  },
  async login({ commit }, code: string) {
    commit('loggingIn')
    await userService.login(code)
    commit('loggedIn')
  },
  async logout(_ctx) {
    await userService.logout()
  }
}

export default actions
