import { Module } from 'vuex'
import actions from '@/store/surveys/actions'
import mutations from '@/store/surveys/mutations'
import { SurveysStateInterface } from '@/models/store/SurveysState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

export const defaultSurvey = () => {
  return {
    multipleResponse: false,
    commonRedirectLinkUsed: true,
    options: []
  }
}

export const initState = () => {
  return {
    loading: false,
    submitting: false,
    loadingOptionCounts: false,
    surveys: [],
    survey: defaultSurvey(),
    filterAndSearch: {},
    optionCounts: []
  } as SurveysStateInterface
}

const surveysModule: Module<SurveysStateInterface, RootStateInterface> = {
  namespaced: true,
  state: initState(),
  actions,
  mutations
}

export default surveysModule
