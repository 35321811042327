const downloadFile = (blob, filename, type = 'application/octet-stream') => {
  const newBlob = new Blob([blob], { type })
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')

  link.href = data
  link.download = filename
  link.click()

  setTimeout(() => {
    window.URL.revokeObjectURL(data)
  }, 100)
}

export { downloadFile }
