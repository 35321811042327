import { createStore } from 'vuex'
import shared from '@/store/shared'
import pagination from '@/store/pagination'
import templates from '@/store/templates'
import preview from '@/store/preview'
import tree from '@/store/tree'
import images from '@/store/images'
import manualSend from '@/store/manual_send'
import surveys from '@/store/surveys'

export default createStore({
  modules: {
    shared,
    pagination,
    templates,
    preview,
    tree,
    images,
    manualSend,
    surveys
  }
})
