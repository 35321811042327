import { GetterTree } from 'vuex'
import { transformVariablesTree } from '@/utils/useTreeHelpers'
import { TreeStateInterface } from '@/models/store/TreeState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import * as consts from '@/utils/constants'

const getters: GetterTree<TreeStateInterface, RootStateInterface> = {
  loadedEditorVariables(
    state: TreeStateInterface,
    getters: { displayFoldersFirst: boolean }
  ) {
    const sorting = consts.TREE_KINDS_WITH_ITEMS_TO_SORT.includes(state.kind)

    return transformVariablesTree({
      tree: state.tree,
      foldersFirst: getters.displayFoldersFirst,
      sorting
    })
  },
  displayFoldersFirst(state: TreeStateInterface) {
    return state.kind === 'variables'
  }
}

export default getters
