import { GetterTree } from 'vuex'
import { ManualSendStateInterface } from '@/models/store/ManualSendState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const getters: GetterTree<ManualSendStateInterface, RootStateInterface> = {
  languages(state: ManualSendStateInterface) {
    const langs = state.templateVariants.map(({ language: l }) => l)
    return [...new Set(langs)]
  },
  countries(state: ManualSendStateInterface) {
    const cs = state.templateVariants
      .filter(({ language: l }) => l === state.language)
      .map(({ country: c }) => c)
    return [...new Set(cs)]
  }
}

export default getters
