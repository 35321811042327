<template>
  <ul id="default-navigation" class="uk-nav uk-flex">
    <li class="uk-margin-small-right">
      <router-link id="templates-link" to="/templates">
        {{ $t('header.navigation.templates') }}
      </router-link>
    </li>
    <li class="uk-margin-small-right">
      <router-link id="surveys-link" to="/surveys">
        {{ $t('header.navigation.surveys') }}
      </router-link>
    </li>
  </ul>
</template>
