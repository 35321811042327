import UIkit from 'uikit'

const timeout = 3000
type statusType = 'primary' | 'success' | 'warning' | 'danger'

const notify = (message: string, status: statusType) => {
  UIkit.notification({
    message: message,
    status: status,
    pos: 'top-right',
    timeout: timeout
  })
}

export { notify }
