import { ActionTree } from 'vuex'
import {
  FromValueInfo,
  parseFromValue,
  transformKeys,
  camelToSnakeCase
} from '@/utils/useSimpleTransformations'
import {
  defaultVariantsInfo,
  isNewTemplateVariant,
  pickDefaultTemplateVariant
} from '@/store/templates/helpers/useTemplatesHelpers'
import {
  createTemplate as createTemplateRequest,
  updateTemplate as updateTemplateRequest,
  restoreTemplate as restoreTemplateRequest,
  fetchTemplate,
  fetchTemplates,
  fetchTemplateVariant,
  exportTemplate as exportTemplateRequest,
  copyTemplateVariants as copyTemplateVariantsRequest,
  destroyTemplateVariants as destroyTemplateVariantsRequest,
  updateTemplateVariants as updateTemplateVariantsRequest,
  fetchVariantsInfo
} from '@/api_client'
import { downloadFile } from '@/utils/useDownloadFile'
import { TemplatesStateInterface } from '@/models/store/TemplatesState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import {
  TemplateInterface,
  KindType
} from '@/models/templates/Template.interface'
import {
  TemplateVariant,
  VariantsInfoInterface
} from '@/models/templates/VariantsInfo.interface'
import CommonTemplateVariantFields from '@/models/templates/CommonTemplateVariantFields.interface'
import { notify } from '@/utils/useNotifications'

const actions: ActionTree<TemplatesStateInterface, RootStateInterface> = {
  async loadTemplates({ commit, state, rootState, dispatch }) {
    commit('loadingTemplates')

    const { page, perPage } = rootState.pagination

    await dispatch('shared/loadConfig', null, { root: true })
    const templatesData = await fetchTemplates({
      page,
      perPage,
      ...state.filterAndSearch
    })

    const { data, totalCount } = templatesData

    commit('loadedTemplates', data as TemplateInterface[])
    dispatch('pagination/updateTotalCount', totalCount, { root: true })
  },
  async loadTemplate({ commit, dispatch }, id) {
    commit('loadingTemplate')

    await dispatch('shared/loadConfig', null, { root: true })
    const template: TemplateInterface = await fetchTemplate(id, {
      loadVersions: true
    })

    dispatch('templateLoaded', template)
  },
  async createTemplate({ commit }, { data, successCallback, failureCallback }) {
    const template: TemplateInterface = await createTemplateRequest(
      data,
      successCallback,
      failureCallback
    )
    commit('updateTemplateState', template)
  },
  async updateTemplate({ commit }, { data }) {
    const template: TemplateInterface = await updateTemplateRequest(
      data.id,
      data
    )
    commit('updateTemplateState', template)
  },
  async loadTemplateVariant(
    { state, commit, dispatch },
    templateVariant: TemplateVariant
  ) {
    const kind = state.template.kind
    const { tags, target } = state.template
    const commonFields = { tags, target } as CommonTemplateVariantFields

    commit('loadingTemplate')

    if (isNewTemplateVariant(state, templateVariant)) {
      dispatch('resetTemplateState', {
        ...templateVariant,
        ...commonFields,
        kind
      })
      dispatch('loadConfigAndSetDefaultTemplateValues')

      return
    }

    await dispatch('shared/loadConfig', null, { root: true })
    const template: TemplateInterface = await fetchTemplateVariant(
      state.slug!,
      {
        ...templateVariant,
        loadVersions: true
      }
    )

    dispatch('templateLoaded', template)
  },
  async removeTemplateVariant(
    { state, commit },
    {
      slug,
      kind,
      templateVariant
    }: { slug: string; kind: KindType; templateVariant: TemplateVariant }
  ) {
    const { language, tonality, country } = templateVariant

    if (isNewTemplateVariant(state, templateVariant)) {
      commit('removeTemplateVariant', templateVariant)
    } else {
      const successCallback = () => {
        commit('removeTemplateVariant', templateVariant)
        notify(
          `Template variant[${language}][${tonality}][${country}] deleted.`,
          'success'
        )
      }
      const params = { slug, kind, ...templateVariant }
      await destroyTemplateVariantsRequest({ params, successCallback })
    }
  },
  async copyTemplateVariants(context, slug: string) {
    const successCallback = () => {
      notify(`Template "${slug}" duplicated.`, 'success')
    }
    copyTemplateVariantsRequest({ slug, successCallback })
  },
  async destroyTemplateVariants(
    { commit },
    { slug, kind }: { slug: string; kind: string }
  ) {
    const successCallback = () => {
      commit('destroyTemplateVariants', slug)
      notify(`Template "${slug}" deleted.`, 'success')
    }
    const params = { slug, kind }
    await destroyTemplateVariantsRequest({ params, successCallback })
  },
  async updateTemplateSlug(
    { state, dispatch },
    { slug, kind }: { slug: string; kind: KindType }
  ) {
    const initSlug = state.slug!

    updateTemplateVariantsRequest({
      slug: initSlug,
      kind,
      data: { slug }
    }).then(() => {
      dispatch('setSlug', slug)

      let message = 'Template updated.'
      if (slug !== initSlug)
        message = `${message} Slug changed from "${initSlug}" to "${slug}"`

      notify(message, 'success')
    })
  },
  async updateCommonTemplateVariantFields(
    { state },
    { kind }: { kind: KindType }
  ) {
    const data = state.newCommonTemplateVariantFields
    if (Object.keys(data).length === 0) return

    await updateTemplateVariantsRequest({
      slug: state.slug!,
      kind,
      data
    })
  },
  setNewCommonTemplateVariantFields(
    { commit },
    data: CommonTemplateVariantFields
  ) {
    commit('setNewCommonTemplateVariantFields', data)
  },
  resetNewCommonTemplateVariantFields({ commit }) {
    commit('resetNewCommonTemplateVariantFields')
  },
  async exportTemplate(context, slug: string) {
    const data = await exportTemplateRequest(slug)
    downloadFile(data, `${slug}.zip`, 'application/zip')
  },
  async restoreTemplate(
    { commit },
    { id, successCallback, failureCallback = () => undefined }
  ) {
    const newTemplate: TemplateInterface = await restoreTemplateRequest(
      id,
      successCallback,
      failureCallback
    )
    commit('updateTemplateState', newTemplate)
  },
  async loadConfigAndSetDefaultTemplateValues({
    commit,
    state,
    rootState,
    dispatch
  }) {
    await dispatch('shared/loadConfig', null, { root: true })

    if (state.template.kind === 'email') {
      const defaultFromAddress = rootState.shared.config.defaultFromAddress
      commit('setDefaultFromAddress', defaultFromAddress)
    }

    commit('resetTemplateChangedFlag')
  },
  setSlug({ commit }, slug: string) {
    commit('setSlug', slug)
  },
  async loadVariantsAndInitialTemplate(
    { state, commit, dispatch },
    templateVariant: TemplateVariant
  ) {
    commit('loadingVariantsInfo')

    const res: { data: { [key: string]: TemplateVariant[] } } =
      await fetchVariantsInfo([state.slug!])
    const data = transformKeys(res.data, camelToSnakeCase)
    const available: TemplateVariant[] = data[state.slug!]

    const variantsInfo: VariantsInfoInterface = {
      ...defaultVariantsInfo(),
      available,
      new: []
    }
    commit('loadedVariantsInfo', variantsInfo)

    const {
      language: defaultLanguage,
      tonality: defaultTonality,
      country: defaultCountry
    } = pickDefaultTemplateVariant(variantsInfo.available)

    const { language, tonality, country } = templateVariant

    dispatch('loadTemplateVariant', {
      language: language ?? defaultLanguage,
      tonality: tonality ?? defaultTonality,
      country: country ?? defaultCountry
    })
  },
  async setFilters({ commit, state, dispatch }, filters) {
    const currentKind = state.filterAndSearch.kind
    if (filters.kind && filters.kind !== currentKind) {
      filters = { ...filters }
      dispatch('pagination/resetState', null, { root: true })
    }

    commit('setFilters', filters)
  },
  async setFiltersAndLoad({ dispatch }, filters) {
    dispatch('setFilters', filters)

    if (filters.kind === 'image') {
      dispatch('images/loadImages', null, { root: true })
    } else {
      dispatch('loadTemplates')
    }
  },
  async setPaginationAndLoad(
    { state, dispatch },
    { page, perPage }: { page?: number; perPage?: number }
  ) {
    dispatch('pagination/updatePagination', { page, perPage }, { root: true })

    if (state.filterAndSearch.kind === 'image') {
      dispatch('images/loadImages', null, { root: true })
    } else {
      dispatch('loadTemplates')
    }
  },
  templateLoaded({ commit }, template: TemplateInterface) {
    let updatedTemplate = { ...template }

    if (updatedTemplate.from) {
      const fromValueInfo: FromValueInfo = parseFromValue(updatedTemplate.from)
      updatedTemplate = { ...updatedTemplate, ...fromValueInfo }
    }

    commit('loadedTemplate', updatedTemplate)
  },
  resetTemplateState({ commit }, data: TemplateInterface) {
    commit('resetTemplateState', data)
  },
  updateTemplateState({ commit }, data) {
    commit('updateTemplateState', data)
  },
  addNewTemplateVariant({ commit }, data) {
    commit('addNewTemplateVariant', data)
  },
  updateCurrentVariant({ commit }, data) {
    commit('updateCurrentVariant', data)
  },
  resetVariantsInfo({ commit }) {
    commit('resetVariantsInfo')
  },
  templateChanged({ commit }) {
    commit('templateChanged')
  },
  markCurrentTemplateVariantPersisted({ commit, getters }) {
    commit(
      'markCurrentTemplateVariantPersisted',
      getters.currentTemplateVariant
    )
  },
  resetTemplateChangedFlag({ commit }) {
    commit('resetTemplateChangedFlag')
  },
  updateAttachment({ commit }, data) {
    commit('updateAttachment', data)
  },
  addNewAttachment({ commit }) {
    commit('addNewAttachment')
  },
  removeAttachment({ commit }, id) {
    commit('removeAttachment', id)
  },
  addNewHsmParam({ commit }) {
    commit('addNewHsmParam')
  },
  removeHsmParam({ commit }) {
    commit('removeHsmParam')
  },
  updateHsmParam({ commit }, { data, index }) {
    commit('updateHsmParam', { data, index })
  }
}

export default actions
