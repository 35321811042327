import { computed } from 'vue'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useActions: useTemplateActions } = createNamespacedHelpers(
  store,
  'templates'
)
const { useActions: usePaginationActions } = createNamespacedHelpers(
  store,
  'pagination'
)
const { setFiltersAndLoad, setPaginationAndLoad } = useTemplateActions([
  'setFiltersAndLoad',
  'setPaginationAndLoad'
])
const { updatePage } = usePaginationActions(['updatePage'])

const page = computed({
  get() {
    return store.state.pagination.page
  },
  set(value) {
    setPaginationAndLoad({ page: value })
  }
})
const perPage = computed({
  get() {
    return store.state.pagination.perPage
  },
  set(value) {
    setPaginationAndLoad({ perPage: value })
  }
})
const totalCount = computed(() => {
  return store.state.pagination.totalCount
})
const pageCount = computed(() => {
  const { perPage, totalCount } = store.state.pagination
  if (totalCount! <= perPage!) return 1

  const reminder = totalCount! % perPage!

  return Math.round(totalCount! / perPage!) + (reminder > 0 ? 1 : 0)
})
const tags = computed({
  get() {
    return store.state.templates.filterAndSearch.tags
  },
  set(value) {
    setFiltersAndLoad({ tags: value })
  }
})
const scope = computed({
  get() {
    return store.state.templates.filterAndSearch.scope
  },
  set(value) {
    setFiltersAndLoad({ scope: value })
  }
})
const target = computed({
  get() {
    return store.state.templates.filterAndSearch.target
  },
  set(value) {
    setFiltersAndLoad({ target: value })
  }
})
const group = computed({
  get() {
    return store.state.templates.filterAndSearch.group
  },
  set(value) {
    setFiltersAndLoad({ group: value })
  }
})
const searchQuery = computed({
  get() {
    return store.state.templates.filterAndSearch.query
  },
  set(value) {
    updatePage(1)
    setFiltersAndLoad({ query: value })
  }
})

export {
  page,
  perPage,
  totalCount,
  pageCount,
  tags,
  scope,
  target,
  group,
  searchQuery
}
