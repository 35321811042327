import { Module } from 'vuex'
import actions from '@/store/shared/actions'
import mutations from '@/store/shared/mutations'
import { SharedStateInterface } from '@/models/store/SharedState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

export const initState = () => {
  return {
    loading: true,
    logging: false,
    config: {
      tags: [],
      targets: [],
      languages: [],
      tonalities: [],
      countries: [],
      groups: [],
      fromAddresses: [],
      defaultFromAddress: null,
      testEmails: [],
      documentTypes: [],
      pairEnvironments: []
    }
  } as SharedStateInterface
}

const sharedModule: Module<SharedStateInterface, RootStateInterface> = {
  namespaced: true,
  state: initState(),
  actions,
  mutations
}

export default sharedModule
