import { Module } from 'vuex'
import actions from '@/store/manual_send/actions'
import mutations from '@/store/manual_send/mutations'
import getters from '@/store/manual_send/getters'
import { defaultTemplateData } from '@/store/templates/helpers/useTemplatesHelpers'
import { RootStateInterface } from '@/models/store/RootState.interface'
import { ManualSendStateInterface } from '@/models/store/ManualSendState.interface'

export const initState = () => {
  return {
    loadingTemplate: false,
    loadingSlugs: false,
    loadingTemplateVariants: false,
    templateSlugs: [],
    language: undefined,
    template: defaultTemplateData(),
    templateVariants: []
  } as ManualSendStateInterface
}

const manualSendModule: Module<ManualSendStateInterface, RootStateInterface> = {
  namespaced: true,
  state: initState(),
  actions,
  mutations,
  getters
}

export default manualSendModule
