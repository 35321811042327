import { ActionTree } from 'vuex'
import {
  sendTestEmail as sendTestEmailRequest,
  makeTemplatePreview as makeTemplatePreviewRequest,
  downloadTemplatePdf,
  fetchTargetData,
  fetchTemplatePreview
} from '@/api_client'
import { ApiError } from '@/utils/useErrorHandlers'
import { downloadFile } from '@/utils/useDownloadFile'
import * as consts from '@/utils/constants'
import { PreviewStateInterface } from '@/models/store/PreviewState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import { TemplatePreviewInfoInterface } from '@/models/preview/TemplatePreviewInfo.interface'
import {
  TemplateInterface,
  TargetType
} from '@/models/templates/Template.interface'

const actions: ActionTree<PreviewStateInterface, RootStateInterface> = {
  async loadTemplatePreview(
    { commit, state },
    { templateId, v2 }: { templateId: string; v2?: boolean }
  ) {
    const { targetId, environment } = state

    commit('loadingTemplatePreview')

    try {
      const data: TemplatePreviewInfoInterface = await fetchTemplatePreview({
        targetId: targetId!,
        templateId,
        environment,
        v2
      })

      commit('templatePreviewLoaded', data)
    } catch (error) {
      if (error instanceof ApiError) {
        commit('templatePreviewFailed', { error: error.message })
      } else {
        console.warn(error)
      }
    }
  },
  async refreshTargetDataAndLoadTemplatePreview(
    { dispatch },
    { template, v2 }: { template: TemplateInterface; v2?: boolean }
  ) {
    await dispatch('loadTargetData', { targetType: template.target })
    dispatch('loadTemplatePreview', { templateId: template.id, v2 })
  },
  async sendTestEmail(
    context,
    { email, templateId, successCallback, failureCallback = () => undefined }
  ) {
    await sendTestEmailRequest(
      email,
      templateId,
      successCallback,
      failureCallback
    )
  },
  async makeTemplatePreview(
    { commit, state },
    { template, v2 }: { template: TemplateInterface; v2?: boolean }
  ) {
    if (!template) return

    const { targetId, environment } = state

    commit('loadingTemplatePreview')

    try {
      const data: TemplatePreviewInfoInterface =
        await makeTemplatePreviewRequest({
          ...template,
          environment,
          v2,
          targetId: targetId!
        })

      commit('templatePreviewLoaded', data)
    } catch (error) {
      if (error instanceof ApiError) {
        commit('templatePreviewFailed', { error: error.message })
      } else {
        console.warn(error)
      }
    }
  },
  async downloadTemplatePdf({ commit, state, dispatch, rootState }) {
    const template = rootState.templates.template
    const environment = state.environment

    commit('downloadingTemplatePdf')

    if (!state.targetId)
      await dispatch('loadTargetData', { targetType: template.target })

    const params = {
      templateId: template.id,
      body: template.body,
      content: template.content,
      targetId: state.targetId,
      environment
    }

    const data = await downloadTemplatePdf(params)

    downloadFile(data, consts.DEFAULT_PREVIEW_FILE_NAME, 'application/pdf')

    commit('downloadedTemplatePdf')
  },
  async refreshTargetDataAndMakeTemplatePreview(
    { dispatch },
    { template, v2 }: { template: TemplateInterface; v2?: boolean }
  ) {
    await dispatch('loadTargetData', { targetType: template.target })
    dispatch('makeTemplatePreview', { template, v2 })
  },
  async loadTargetData(
    { commit, state },
    { targetType, targetId }: { targetType: TargetType; targetId?: string }
  ) {
    commit('reloadingTargetData')

    const { target }: { target: { pan: string; id: string } } =
      await fetchTargetData(state.environment, targetType, targetId)

    commit('reloadedTargetData', { targetId: target.id, targetType })
  },
  updateTemplatePreviewEnvironment({ commit }, newEnvironment) {
    commit('updateTemplatePreviewEnvironment', newEnvironment)
  },
  resetTargetData({ commit }) {
    commit('resetTargetData')
  }
}

export default actions
