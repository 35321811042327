import { MutationTree } from 'vuex'
import { processLoadedConfig } from '@/store/shared/helpers/useSharedHelpers'
import { SharedStateInterface } from '@/models/store/SharedState.interface'
import { ConfigInterface } from '@/models/Config.interface'

const mutations: MutationTree<SharedStateInterface> = {
  loadedAppVersion(state: SharedStateInterface, appVersion: string) {
    state.appVersion = appVersion
  },
  loadedConfig(state: SharedStateInterface, data: ConfigInterface) {
    state.config = processLoadedConfig(data)
    state.loading = false
  },
  loggingIn(state: SharedStateInterface) {
    state.logging = true
  },
  loggedIn(state: SharedStateInterface) {
    state.logging = false
  }
}

export default mutations
