import applyCaseMiddleware from 'axios-case-converter'
import axios from 'axios'
import { errorHandler } from '@/utils/useErrorHandlers'
import { KindType, TargetType } from '@/models/templates/Template.interface'
import CommonTemplateVariantFields from '@/models/templates/CommonTemplateVariantFields.interface'
import { SurveyInterface } from '@/models/surveys/Survey.interface'

const devHostUrl = () => {
  if (process.env.VUE_APP_API_URL) {
    return process.env.VUE_APP_API_URL
  } else {
    const port = process.env.VUE_APP_REAL_API ? '3000' : '3001'
    return `http://localhost:${port}`
  }
}
const envHostUrls: Record<string, string | undefined> = {
  development: devHostUrl(),
  production: process.env.VUE_APP_API_ENDPOINT
}
const withCredentials = !process.env.VUE_APP_AUTH_DISABLED
const client = applyCaseMiddleware(axios.create({ withCredentials }))
const hostUrl = envHostUrls[process.env.NODE_ENV || '']
const urlApi = `${hostUrl}/communication/api/v1`

const downloadClient = applyCaseMiddleware(
  axios.create({
    responseType: 'arraybuffer',
    responseEncoding: 'binary',
    withCredentials
  })
)

const fetchAppVersion = () =>
  client
    .get(`${hostUrl}/communication/api/version`)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchTemplates = (params: object) =>
  client
    .get(`${urlApi}/templates`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchTemplate = (id: string, params: object) =>
  client
    .get(`${urlApi}/templates/${id}`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchTemplateVariant = (slug: string, params: object) =>
  client
    .get(`${urlApi}/template_variants/${slug}`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const copyTemplateVariants = ({
  slug,
  successCallback
}: {
  slug: string
  successCallback: () => void
}) =>
  client
    .post(`${urlApi}/template_variants/${slug}/copy`)
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => errorHandler(error))

const destroyTemplateVariants = ({
  params,
  successCallback
}: {
  params: {
    slug: string
    kind: string
    language?: string
    tonality?: string
    country?: string
  }
  successCallback: () => void
}) => {
  const { slug } = params

  return client
    .delete(`${urlApi}/template_variants/${slug}`, { params })
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => errorHandler(error))
}

const updateTemplateVariants = ({
  slug,
  kind,
  data
}: {
  slug: string
  kind: KindType
  data: CommonTemplateVariantFields
}) =>
  client
    .put(`${urlApi}/template_variants/${slug}`, { kind, data })
    .catch(errorHandler)

const exportTemplate = (slug: string) =>
  downloadClient
    .post(`${urlApi}/template_variants/${slug}/export`)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const createTemplate = (
  data: object,
  successCallback: () => void,
  failureCallback: () => void
) =>
  client
    .post(`${urlApi}/templates`, data)
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => {
      failureCallback()
      errorHandler(error)
    })

const updateTemplate = (id: string, data: object) =>
  client
    .put(`${urlApi}/templates/${id}`, data)
    .then((r) => r.data)
    .catch(errorHandler)

const restoreTemplate = (
  id: string,
  successCallback: () => void,
  failureCallback: () => void
) =>
  client
    .put(`${urlApi}/templates/${id}/restore`)
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => {
      failureCallback()
      errorHandler(error)
    })

const fetchTemplateSlugs = (params: object) =>
  client
    .get(`${urlApi}/template_slugs`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchConfig = () =>
  client
    .get(`${urlApi}/configurations`)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchEditorVariables = ({
  kind,
  parentId,
  query,
  onlyChildren,
  page,
  perPage,
  filter
}: {
  kind: string
  parentId?: number
  query?: string
  onlyChildren?: boolean
  page?: number
  perPage?: number
  filter?: { template?: { target: TargetType } }
}) => {
  onlyChildren = typeof onlyChildren === 'undefined' ? true : onlyChildren

  const params = { kind, parentId, query, onlyChildren, page, perPage, filter }
  return client
    .get(`${urlApi}/variable_nodes`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))
}

const createEditorVariableNode = (
  data: object,
  successCallback: () => void,
  failureCallback: () => void
) =>
  client
    .post(`${urlApi}/variable_nodes`, data)
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => {
      failureCallback()
      errorHandler(error)
    })

const deleteEditorVariableNode = (id: number) =>
  client
    .delete(`${urlApi}/variable_nodes/${id}`)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const updateEditorVariableNode = (
  id: number,
  data: object,
  successCallback: () => void,
  failureCallback: () => void
) =>
  client
    .put(`${urlApi}/variable_nodes/${id}`, data)
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => {
      failureCallback()
      errorHandler(error)
    })

const fetchImages = (params: object) =>
  client
    .get(`${urlApi}/images`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const deleteImage = (id: number) =>
  client
    .delete(`${urlApi}/images/${id}`)
    .then((r) => r.data)
    .catch((e) => errorHandler(e))

const fetchTemplatePreview = (params: object) =>
  client
    .get(`${urlApi}/rendering/preview`, { params })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const makeTemplatePreview = (data: object) =>
  client
    .post(`${urlApi}/rendering/live_preview`, data)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const downloadTemplatePdf = (params: object) =>
  downloadClient
    .post(`${urlApi}/rendering/download_pdf`, params)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const uploadImageUrl = `${urlApi}/images`

const sendTestEmail = (
  email: string,
  templateId: string,
  successCallback: () => void,
  failureCallback: () => void
) =>
  client
    .post(`${urlApi}/templates/${templateId}/send-email`, { to: email })
    .then((response) => {
      successCallback()
      return response.data
    })
    .catch((error) => {
      failureCallback()
      errorHandler(error)
    })

const login = (code: string) =>
  client
    .post(`${urlApi}/sessions`, { code })
    .then((r) => r.data)
    .catch((e) => errorHandler(e))

const logout = () =>
  client
    .delete(`${urlApi}/sessions`)
    .then((r) => r.data)
    .catch((e) => errorHandler(e))

const fetchVariantsInfo = (slugs: string[]) =>
  client
    .get(`${urlApi}/template_variants/info`, { params: { slugs } })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchTargetVariables = (
  environment: string,
  targetType: TargetType,
  targetId: string | number
) =>
  client
    .get(`${urlApi}/variables`, {
      params: { environment, targetType, targetId }
    })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const fetchTargetData = (
  environment: string,
  targetType: TargetType,
  targetId: string | undefined
) =>
  client
    .get(`${urlApi}/target_data`, {
      params: { environment, targetType, targetId }
    })
    .then((response) => response.data)
    .catch((error) => errorHandler(error))

const manualSend = (data: object) => {
  return client
    .post(`${urlApi}/templates/manual-send`, data)
    .then((response) => response.data)
    .catch((error) => errorHandler(error))
}

const createSurvey = (data: object) =>
  client
    .post(`${urlApi}/surveys`, data)
    .then((r) => r.data)
    .catch(errorHandler)

const fetchSurvey = (id: number) =>
  client
    .get(`${urlApi}/surveys/${id}`)
    .then((r) => r.data)
    .catch((error) => errorHandler(error))

const updateSurvey = (data: SurveyInterface) =>
  client
    .put(`${urlApi}/surveys/${data.id}`, data)
    .then((r) => r.data)
    .catch(errorHandler)

const fetchSurveys = (params: object) =>
  client
    .get(`${urlApi}/surveys`, { params })
    .then((r) => r.data)
    .catch(errorHandler)

const fetchOptionCounts = (params: object) =>
  client
    .get(`${urlApi}/survey_responses/option_counts`, { params })
    .then((r) => r.data)
    .catch(errorHandler)

export {
  fetchAppVersion,
  fetchTemplates,
  fetchTemplate,
  fetchTemplateVariant,
  copyTemplateVariants,
  destroyTemplateVariants,
  updateTemplateVariants,
  exportTemplate,
  createTemplate,
  updateTemplate,
  restoreTemplate,
  fetchTemplateSlugs,
  fetchConfig,
  fetchEditorVariables,
  createEditorVariableNode,
  deleteEditorVariableNode,
  updateEditorVariableNode,
  fetchImages,
  deleteImage,
  fetchTemplatePreview,
  makeTemplatePreview,
  downloadTemplatePdf,
  sendTestEmail,
  login,
  logout,
  fetchVariantsInfo,
  fetchTargetVariables,
  fetchTargetData,
  uploadImageUrl,
  manualSend,
  withCredentials,
  createSurvey,
  fetchSurvey,
  updateSurvey,
  fetchSurveys,
  fetchOptionCounts
}
