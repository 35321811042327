import { GetterTree } from 'vuex'
import { PreviewStateInterface } from '@/models/store/PreviewState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const getters: GetterTree<PreviewStateInterface, RootStateInterface> = {
  caseFileSlug(state: PreviewStateInterface) {
    if (state.targetType === 'case_file' && state.targetId) {
      return String(state.targetId).split(':')?.at(-1)
    }
  }
}

export default getters
