import { MutationTree } from 'vuex'
import { clearedPreviewFields } from '@/store/preview/helpers/usePreviewHelpers'
import { PreviewStateInterface } from '@/models/store/PreviewState.interface'
import { TemplatePreviewInfoInterface } from '@/models/preview/TemplatePreviewInfo.interface'
import { FailureInterface } from '@/models/preview/Failure.interface'
import { TargetType } from '@/models/templates/Template.interface'

const mutations: MutationTree<PreviewStateInterface> = {
  loadingTemplatePreview(state: PreviewStateInterface) {
    Object.assign(state, {
      ...clearedPreviewFields(),
      loading: true,
      error: null
    })
  },
  templatePreviewLoaded(
    state: PreviewStateInterface,
    data: TemplatePreviewInfoInterface
  ) {
    Object.assign(state, {
      ...data,
      error: null,
      loading: false
    })
  },
  templatePreviewFailed(state: PreviewStateInterface, data: FailureInterface) {
    Object.assign(state, {
      ...data,
      loading: false
    })
  },
  updateTemplatePreviewEnvironment(
    state: PreviewStateInterface,
    newEnvironment: string
  ) {
    state.environment = newEnvironment
  },
  downloadingTemplatePdf(state: PreviewStateInterface) {
    state.downloadingPdf = true
  },
  downloadedTemplatePdf(state: PreviewStateInterface) {
    state.downloadingPdf = false
  },
  reloadingTargetData(state: PreviewStateInterface) {
    state.loadingTargetData = true
  },
  reloadedTargetData(
    state: PreviewStateInterface,
    { targetId, targetType }: { targetId: string; targetType: TargetType }
  ) {
    state.loadingTargetData = false
    state.targetId = targetId
    state.targetType = targetType
  },
  resetTargetData(state: PreviewStateInterface) {
    state.targetId = undefined
  }
}

export default mutations
