import { Module } from 'vuex'
import actions from '@/store/templates/actions'
import mutations from '@/store/templates/mutations'
import getters from '@/store/templates/getters'
import {
  defaultTemplateData,
  defaultVariantsInfo
} from '@/store/templates/helpers/useTemplatesHelpers'
import { TemplatesStateInterface } from '@/models/store/TemplatesState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

export const initState = () => {
  return {
    loading: true,
    slug: null,
    templates: [],
    template: defaultTemplateData(),
    variantsInfo: defaultVariantsInfo(),
    newCommonTemplateVariantFields: {},
    filterAndSearch: {
      tags: null,
      scope: null,
      target: null,
      group: null,
      kind: 'email',
      query: null
    }
  } as TemplatesStateInterface
}

const templatesModule: Module<TemplatesStateInterface, RootStateInterface> = {
  namespaced: true,
  state: initState(),
  actions,
  mutations,
  getters
}

export default templatesModule
