import { Module } from 'vuex'
import actions from '@/store/images/actions'
import mutations from '@/store/images/mutations'
import { ImagesStateInterface } from '@/models/store/ImagesState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const imagesModule: Module<ImagesStateInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    images: [],
    loading: true
  },
  actions,
  mutations
}

export default imagesModule
