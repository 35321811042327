import { MutationTree } from 'vuex'
import { findVariableNode } from '@/utils/useTreeHelpers'
import {
  TreeStateInterface,
  TreeKind
} from '@/models/store/TreeState.interface'
import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'

const mutations: MutationTree<TreeStateInterface> = {
  loadingEditorVariables(state: TreeStateInterface) {
    state.loading = true
    state.tree = []
  },
  loadedEditorVariables(
    state: TreeStateInterface,
    tree: VariableNodeInterface[]
  ) {
    state.tree = tree
    state.loading = false
  },
  createEditorVariableNode(
    state: TreeStateInterface,
    newNode: VariableNodeInterface
  ) {
    state.tree.unshift(newNode)
  },
  deleteEditorVariableNode(state: TreeStateInterface, id: number) {
    state.tree = state.tree.filter((node) => node.id !== id)
  },
  updateEditorVariableNode(
    state: TreeStateInterface,
    newNode: VariableNodeInterface
  ) {
    if (newNode.order !== undefined) return

    const targetNode: VariableNodeInterface | undefined = findVariableNode(
      state.tree,
      newNode.id
    )

    let nodes: VariableNodeInterface[] = state.tree
    if (newNode.parentId) {
      const parentNode: VariableNodeInterface | undefined = findVariableNode(
        state.tree,
        newNode.parentId
      )
      nodes = parentNode?.children || []
    }

    const index = nodes.findIndex((node) => node.id === newNode.id)
    nodes[index] = { ...targetNode, ...newNode }
  },
  toggleTreeView(state: TreeStateInterface) {
    state.treeView = !state.treeView
  },
  setTreeKind(state: TreeStateInterface, kind: TreeKind) {
    state.kind = kind
  }
}

export default mutations
