import { ConfigInterface } from '@/models/Config.interface'

const processLoadedConfig = (data: ConfigInterface): ConfigInterface => {
  data.testEmails.sort()

  return {
    ...data,
    defaultFromAddress: data.fromAddresses[0]
  }
}

export { processLoadedConfig }
