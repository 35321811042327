import { MutationTree } from 'vuex'
import * as consts from '@/utils/constants'
import {
  defaultTemplateData,
  setTemplateVariantToTemplate,
  addTemplateVariant,
  defaultVariantsInfo,
  removeTemplateVariant
} from '@/store/templates/helpers/useTemplatesHelpers'
import { TemplatesStateInterface } from '@/models/store/TemplatesState.interface'
import { FilterAndSearchInterface } from '@/models/templates/FilterAndSearch.interface'
import { TemplateInterface } from '@/models/templates/Template.interface'
import {
  VariantsInfoInterface,
  TemplateVariant
} from '@/models/templates/VariantsInfo.interface'
import { AttachmentInterface } from '@/models/templates/Attachment.interface'
import CommonTemplateVariantFields from '@/models/templates/CommonTemplateVariantFields.interface'

const mutations: MutationTree<TemplatesStateInterface> = {
  loadingTemplates(state: TemplatesStateInterface) {
    state.loading = true
    state.templates = []
  },
  loadedTemplates(
    state: TemplatesStateInterface,
    templates: TemplateInterface[]
  ) {
    state.templates = templates
    state.loading = false
  },
  loadingTemplate(state: TemplatesStateInterface) {
    state.loading = true
    state.template = defaultTemplateData()
  },
  loadedTemplate(state: TemplatesStateInterface, template: TemplateInterface) {
    state.template = template
    state.loading = false
  },
  setFilters(
    state: TemplatesStateInterface,
    filters: FilterAndSearchInterface
  ) {
    state.filterAndSearch = { ...state.filterAndSearch, ...filters }
  },
  updateTemplateState(state: TemplatesStateInterface, data: TemplateInterface) {
    state.template = Object.assign(state.template, data)
  },
  resetTemplateState(state: TemplatesStateInterface, data: TemplateInterface) {
    state.template = {
      ...defaultTemplateData(),
      ...data,
      slug: state.slug!
    }

    if (
      state.template.kind &&
      consts.TEMPLATE_WITHOUT_CONTENT_FIELD_KINDS.includes(state.template.kind)
    )
      state.template.body = ''

    state.loading = false
  },
  setDefaultFromAddress(state: TemplatesStateInterface, fromAddress: string) {
    if (state.template) state.template.fromAddress = fromAddress
  },
  loadedVariantsInfo(
    state: TemplatesStateInterface,
    data: VariantsInfoInterface
  ) {
    state.variantsInfo = {
      ...state.variantsInfo,
      ...data
    }
    state.variantsInfo.loading = false
  },
  setSlug(state: TemplatesStateInterface, slug: string) {
    state.slug = slug
    state.template.slug = slug
  },
  loadingVariantsInfo(state: TemplatesStateInterface) {
    state.variantsInfo.loading = true
  },
  addNewTemplateVariant(
    state: TemplatesStateInterface,
    templateVariant: TemplateVariant
  ) {
    addTemplateVariant(state, 'new', templateVariant)
    addTemplateVariant(state, 'available', templateVariant)
  },
  removeTemplateVariant(
    state: TemplatesStateInterface,
    templateVariant: TemplateVariant
  ) {
    removeTemplateVariant(state, 'new', templateVariant)
    removeTemplateVariant(state, 'available', templateVariant)
  },
  destroyTemplateVariants(state: TemplatesStateInterface, slug: string) {
    state.templates = state.templates.filter(
      (template) => template.slug !== slug
    )
  },
  updateCurrentVariant(
    state: TemplatesStateInterface,
    templateVariant: TemplateVariant
  ) {
    state.template = { ...state.template, ...templateVariant }
  },
  setNewCommonTemplateVariantFields(
    state: TemplatesStateInterface,
    data: CommonTemplateVariantFields
  ) {
    state.newCommonTemplateVariantFields = {
      ...state.newCommonTemplateVariantFields,
      ...data
    }
  },
  resetNewCommonTemplateVariantFields(state: TemplatesStateInterface) {
    state.newCommonTemplateVariantFields = {}
  },
  resetVariantsInfo(state: TemplatesStateInterface) {
    state.variantsInfo = { ...defaultVariantsInfo(), loading: false }
    setTemplateVariantToTemplate(state)
  },
  templateChanged(state: TemplatesStateInterface) {
    state.template.changed = true
  },
  resetTemplateChangedFlag(state: TemplatesStateInterface) {
    state.template.changed = false
  },
  markCurrentTemplateVariantPersisted(
    state: TemplatesStateInterface,
    templateVariant: TemplateVariant
  ) {
    removeTemplateVariant(state, 'new', templateVariant)
  },
  updateAttachment(state: TemplatesStateInterface, data: AttachmentInterface) {
    const attachment = state.template.attachmentsMetadata.find(
      (att) => att.id === data.id
    )
    Object.assign(attachment, data)
  },
  addNewAttachment(state: TemplatesStateInterface) {
    let id: number
    if (state.template.attachmentsMetadata.length > 0) {
      const ids = state.template.attachmentsMetadata.map((att) => att.id)
      const sortedIds = ids.sort((a, b) => b - a)
      id = sortedIds[0] + 1
    } else {
      id = 1
    }
    const newAttachment: AttachmentInterface = {
      id,
      type: consts.DEFAULT_ATTACHMENT_TYPE
    }

    state.template.attachmentsMetadata.push(newAttachment)
  },
  removeAttachment(state: TemplatesStateInterface, id: number) {
    state.template.attachmentsMetadata =
      state.template.attachmentsMetadata.filter((att) => att.id !== id)
  },
  addNewHsmParam(state: TemplatesStateInterface) {
    if (!state.template.hsmParams?.length) {
      state.template.hsmParams = []
    }

    state.template.hsmParams.push('')
  },
  removeHsmParam(state: TemplatesStateInterface) {
    if (!state.template.hsmParams?.length) {
      state.template.hsmParams = []
    }

    state.template.hsmParams.pop()
  },
  updateHsmParam(state: TemplatesStateInterface, { data, index }) {
    if (!state.template.hsmParams?.length) {
      state.template.hsmParams = []
    }

    state.template.hsmParams[index] = data
  }
}

export default mutations
