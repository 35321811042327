import { notify } from '@/utils/useNotifications'
import axios, { AxiosError } from 'axios'
import { userService } from '@/services/User.service'

const DEFAULT_ERR_MSG = 'Something went wrong'

class ApiError extends Error {}

const errorHandler = (error: AxiosError | Error) => {
  let message: string | null = null

  if (axios.isAxiosError(error) && error instanceof AxiosError) {
    if ([401, 403].includes(error.response!.status)) {
      userService.logout(false)
      location.href = '/'
    }
    if (error.response) {
      const { errors, data: { body = undefined } = {} } = error.response.data

      message = errors
      if (body) message += `<br>${body}`
    }
  } else {
    message = error.message
  }
  message = message ?? DEFAULT_ERR_MSG

  notify(message, 'danger')
  throw new ApiError(message)
}

export { errorHandler, ApiError }
