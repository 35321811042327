import { MutationTree } from 'vuex'
import { ManualSendStateInterface } from '@/models/store/ManualSendState.interface'
import { TemplateInterface } from '@/models/templates/Template.interface'
import { TemplateVariant } from '@/models/templates/VariantsInfo.interface'

const mutations: MutationTree<ManualSendStateInterface> = {
  loadingTemplate(state: ManualSendStateInterface) {
    state.loadingTemplate = true
  },
  loadedTemplate(state: ManualSendStateInterface, template: TemplateInterface) {
    state.template = template
    state.loadingTemplate = false
  },
  loadingTemplateSlugs(state: ManualSendStateInterface) {
    state.loadingSlugs = true
    state.templateSlugs = []
  },
  loadedTemplateSlugs(state: ManualSendStateInterface, slugs: string[]) {
    state.templateSlugs = slugs
    state.loadingSlugs = false
  },
  updateTemplate(state: ManualSendStateInterface, template: TemplateInterface) {
    state.template = { ...state.template, ...template }
  },
  loadingTemplateVariants(state: ManualSendStateInterface) {
    state.loadingTemplateVariants = true
  },
  loadedTemplateVariants(
    state: ManualSendStateInterface,
    templateVariants: TemplateVariant[]
  ) {
    state.templateVariants = templateVariants
    state.loadingTemplateVariants = false
  },
  setLanguage(state: ManualSendStateInterface, language?: string) {
    state.language = language
  }
}

export default mutations
