import { MutationTree } from 'vuex'
import { defaultSurvey } from '@/store/surveys'
import { SurveysStateInterface } from '@/models/store/SurveysState.interface'
import { SurveyInterface } from '@/models/surveys/Survey.interface'
import { FilterAndSearchInterface } from '@/models/surveys/FilterAndSearch.interface'
import { OptionCountInterface } from '@/models/surveys/OptionCount.interface'

const mutations: MutationTree<SurveysStateInterface> = {
  updateSurveyState(state: SurveysStateInterface, data: SurveyInterface) {
    state.survey = Object.assign(state.survey, data)
  },
  submittingSurvey(state: SurveysStateInterface) {
    state.submitting = true
  },
  submittedSurvey(state: SurveysStateInterface) {
    state.submitting = false
  },
  addNewOption(state: SurveysStateInterface) {
    state.survey.options.push({})
  },
  removeOption(state: SurveysStateInterface, option) {
    const optionIndex = state.survey.options.indexOf(option)
    state.survey.options.splice(optionIndex, 1)
  },
  updateOption(state: SurveysStateInterface, { option, data }) {
    const optionIndex = state.survey.options.indexOf(option)
    const optionToUpdate = state.survey.options[optionIndex]

    Object.assign(optionToUpdate, data)
  },
  loadingSurvey(state: SurveysStateInterface) {
    state.loading = true
  },
  loadedSurvey(state: SurveysStateInterface) {
    state.loading = false
  },
  loadingSurveys(state: SurveysStateInterface) {
    state.loading = true
  },
  loadedSurveys(state: SurveysStateInterface) {
    state.loading = false
  },
  updateSurveys(state: SurveysStateInterface, surveys: SurveyInterface[]) {
    state.surveys = surveys
  },
  setFilters(state: SurveysStateInterface, filters: FilterAndSearchInterface) {
    state.filterAndSearch = { ...state.filterAndSearch, ...filters }
  },
  loadingOptionCounts(state: SurveysStateInterface) {
    state.loadingOptionCounts = true
  },
  loadedOptionCounts(state: SurveysStateInterface) {
    state.loadingOptionCounts = false
  },
  updateOptionCounts(
    state: SurveysStateInterface,
    optionCounts: OptionCountInterface[]
  ) {
    state.optionCounts = optionCounts
  },
  resetSurvey(state: SurveysStateInterface) {
    state.survey = defaultSurvey()
  }
}

export default mutations
