import { UserInterface } from '@/models/users/User.interface'
import { login as loginUser, logout as logoutUser } from '@/api_client'

// eslint-disable-next-line
const login = async (code: string) => {
  const user: UserInterface = await loginUser(code)
  user.email && localStorage.setItem('user', JSON.stringify(user))
}

const logout = async (apiCall = true) => {
  if (apiCall) await logoutUser()
  localStorage.removeItem('user')
}

const isLoggedIn = () => {
  const userData: string | null = localStorage.getItem('user')
  if (!userData) return false

  const user: UserInterface = JSON.parse(userData)
  return !!user.email
}

export const userService = {
  login,
  logout,
  isLoggedIn
}
