import { ActionTree } from 'vuex'
import { ManualSendStateInterface } from '@/models/store/ManualSendState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'
import {
  fetchTemplateVariant,
  fetchTemplateSlugs,
  fetchVariantsInfo,
  manualSend
} from '@/api_client'
import { notify } from '@/utils/useNotifications'
import {
  camelToSnakeCase,
  transformKeys
} from '@/utils/useSimpleTransformations'
import { TemplateVariant } from '@/models/templates/VariantsInfo.interface'
import { TemplateInterface } from '@/models/templates/Template.interface'
import ManualSendType from '@/models/manual_send/ManualSendType'
import { ManualSendEmailDataInterface } from '@/models/manual_send/ManualSendEmailData.interface'
import * as consts from '@/utils/constants'

const actions: ActionTree<ManualSendStateInterface, RootStateInterface> = {
  async loadTemplateVariant(
    { commit },
    {
      slug,
      language,
      country
    }: { slug: string; language: string; country: string }
  ) {
    commit('loadingTemplate')

    const templateVariant = {
      language,
      country,
      tonality: consts.DEFAULT_TONALITY
    }

    const template: TemplateInterface = await fetchTemplateVariant(slug, {
      ...templateVariant,
      loadVersions: false
    })

    commit('loadedTemplate', template)
  },
  async loadTemplateSlugs({ commit }, { kind }: { kind: string }) {
    commit('loadingTemplateSlugs')

    const params = { kind, group: consts.MANUAL_SEND_GROUP }
    const response = await fetchTemplateSlugs(params)
    const slugs: string[] = response.data

    commit('loadedTemplateSlugs', slugs)
  },
  async loadTemplateVariantsInfo({ commit }, templateSlug: string) {
    commit('loadingTemplateVariants')

    const res: { data: { [key: string]: TemplateVariant[] } } =
      await fetchVariantsInfo([templateSlug])
    const data = transformKeys(res.data, camelToSnakeCase)

    commit('loadedTemplateVariants', data[templateSlug])
  },
  async sendTemplate(
    { state },
    {
      caseFilePan,
      kind,
      messageData
    }: {
      caseFilePan: string
      kind: ManualSendType
      messageData: ManualSendEmailDataInterface
    }
  ) {
    manualSend({ caseFilePan, kind, messageData }).then(() =>
      notify('Sent', 'success')
    )
    notify(`Sending "${state.template.slug}" ${kind}`, 'primary')
  },
  updateTemplate({ commit }, template: TemplateInterface) {
    commit('updateTemplate', template)
  },
  setLanguage({ commit }, language?: string) {
    commit('setLanguage', language)
  }
}

export default actions
