export default {
  "header": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication service"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "navigation": {
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])}
    }
  },
  "index_templates": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View and manage system templates"])},
    "item_tree_switcher": {
      "tree_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree view"])},
      "table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table view"])}
    },
    "filter_and_search": {
      "page_amount": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per page"])}
      },
      "tags": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter tags"])}
      },
      "search": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "scope": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope"])},
        "types": {
          "unapproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unapproved"])},
          "unapproved_without_nested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unapproved without nested"])},
          "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])}
        }
      },
      "target": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
        "types": {
          "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
          "case_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case file"])},
          "creditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor"])}
        }
      },
      "group": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])}
      }
    },
    "templates_table": {
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "tonality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonalities"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "action_names": {
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "destroy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destroy"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      }
    },
    "template_actions": {
      "new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New template"])}
    },
    "image_actions": {
      "new_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New image"])}
    },
    "images_table": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
    },
    "templates_tabs": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails"])},
      "letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letters"])},
      "plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain"])},
      "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
      "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp"])},
      "mjml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MJML"])},
      "html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])}
    }
  },
  "images": {
    "upload": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
      "title": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])}
      }
    },
    "drop_area": {
      "attach_files_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach an image by dropping it here or"])},
      "select_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecting one"])}
    }
  },
  "show_template": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the template"])},
    "template_info": {
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
      "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template for"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "tonality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonality"])},
      "colored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print in color"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
    },
    "buttons": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
    },
    "template_preview": {
      "target": {
        "case_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case file"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
        "creditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor"])}
      },
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "hsm_param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsm param"])},
      "env": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["env"])},
      "define_env": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define environment to get data from"])},
      "edit_slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit slug"])},
      "refresh_case_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull out new case file slug and refresh preview"])}
    },
    "attachments": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments:"])}
    }
  },
  "show_template_version": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template version"])},
    "buttons": {
      "view_most_recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View most recent version"])},
      "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])}
    }
  },
  "edit_template": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and edit templates"])},
    "buttons": {
      "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create ", _interpolate(_named("kind")), " template"])},
      "update": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update ", _interpolate(_named("kind")), " template"])}
    },
    "versions_panel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions"])},
      "restore_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])}
    },
    "variants_panel": {
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "tonalities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonalities"])},
      "countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])}
    },
    "variant_selection": {
      "new_variant_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New language"])},
      "new_variant_tonality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New tonality"])},
      "new_variant_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New country"])}
    }
  },
  "manual_send": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manual ", _interpolate(_named("kind")), " send"])},
    "variables_header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
      "case_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case file"])}
    },
    "manual_send_form": {
      "preview_before_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview before sending!"])}
    },
    "send_form": {
      "templates": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose template"])}
      },
      "languages": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose language"])}
      },
      "countries": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])}
      },
      "subject": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])}
      },
      "content": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])}
      }
    }
  },
  "edit_survey": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create and edit surveys"])},
    "survey_form": {
      "name": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Name"])}
      },
      "description": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use case"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the use case"])}
      },
      "multiple_response": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple Response Allowed?"])}
      },
      "common_redirect_link_used": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Redirect Link?"])}
      },
      "common_redirect_link": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect Link"])}
      },
      "options": {
        "head": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Options"])},
          "archived_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived options"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
        },
        "redirect_link": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect Link"])}
        },
        "at_least_one_option_defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one option has to be defined."])},
        "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
        "unarchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])}
      },
      "buttons": {
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create survey"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update survey"])},
        "show_archived_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show archived options"])},
        "hide_archived_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide archived options"])}
      }
    }
  },
  "show_survey": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the survey"])},
    "survey_info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use case"])},
      "multipleResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple Response Allowed?"])},
      "commonRedirectLinkUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Redirect Link?"])},
      "commonRedirectLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect Link"])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "options": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "redirectLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect link"])}
      }
    },
    "survey_responses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
      "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload responses statistics"])}
    }
  },
  "list_surveys": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View and manage surveys"])},
    "filter_and_search": {
      "search": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])}
      }
    },
    "surveys_table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use case"])},
      "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])}
    },
    "survey_actions": {
      "new_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New survey"])}
    }
  },
  "shared": {
    "template_form": {
      "slug_input": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])}
      },
      "target_select": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template for"])}
      },
      "group_select": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose group"])}
      },
      "description_textarea": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
      },
      "subject_input": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])}
      },
      "from_input": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])}
      },
      "body_textarea": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])}
      },
      "content_textarea": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])}
      },
      "tags_select": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose tags"])},
        "tag_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to create a tag"])}
      },
      "colored_input": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print in color"])}
      },
      "template_editor": {
        "variable_tabs": {
          "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
          "snippets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snippets"])},
          "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
          "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
          "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveys"])}
        }
      },
      "editor_actions": {
        "toggle_preview_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview on"])},
        "toggle_preview_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview off"])},
        "body_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body editor"])},
        "send_test_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send test email"])},
        "pdf_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF preview"])}
      },
      "body_editor": {
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])}
      },
      "search_bar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "attachments": {
        "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments:"])},
        "fields": {
          "document_type": {
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type"])},
            "as_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As link"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose document type"])}
          }
        },
        "buttons": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])}
        }
      },
      "hsm_params": {
        "fields": {
          "hsm_param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hsm param"])}
        },
        "buttons": {
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add param"])},
          "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove param"])}
        }
      }
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "preview_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview on"])},
    "preview_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview off"])},
    "modals": {
      "send_test_email": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send test email"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose email"])},
        "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])}
      },
      "template_slug": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template slug"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter template slug..."])}
      }
    },
    "pagination": {
      "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "page_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page amount"])}
    },
    "pair_environments": {
      "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
      "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
      "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
      "se": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])}
    }
  },
  "login": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communications"])},
    "login_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "azure_login_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azure Login (PAIR Admin)"])},
    "pair_logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pair Finance"])},
    "azure_logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azure Login"])}
  }
}