import { defineComponent, ref, onMounted, computed } from 'vue'
import { Draggable, BaseTree } from '@he-tree/vue3'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

import { mapTreeKindToType } from '@/utils/useSimpleTransformations'
import { notify } from '@/utils/useNotifications'
import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'

export default defineComponent({
  props: {
    type: { type: String, default: 'snippets' },
    editModeAllowed: { type: Boolean, default: true }
  },
  emits: ['variableClicked'],
  setup(props, { emit, slots }) {
    const { useActions, useGetters } = createNamespacedHelpers('tree')

    const {
      loadEditorVariables,
      createEditorVariableNode,
      deleteEditorVariableNode,
      updateEditorVariableNode,
      filterEditorVariableNodes,
      loadEditorVariableChildren
    } = useActions([
      'loadEditorVariables',
      'createEditorVariableNode',
      'deleteEditorVariableNode',
      'updateEditorVariableNode',
      'filterEditorVariableNodes',
      'loadEditorVariableChildren'
    ])
    const { loadedEditorVariables } = useGetters(['loadedEditorVariables'])

    onMounted(() => loadEditorVariables({ kind: props.type }))

    const editMode = ref(false)
    const currentEditFolderId = ref<number>()
    const treeData = loadedEditorVariables
    const kind = computed(() => mapTreeKindToType(props.type))

    const createFolderHandler = (name: string) => {
      createEditorVariableNode({
        data: { kind: kind.value, name },
        successCallback() {
          notify('New folder has been created', 'success')
        }
      })
    }
    const updateFolderNameHandler = (name: string) => {
      updateEditorVariableNode({
        data: { id: currentEditFolderId.value, name },
        successCallback() {
          notify('Folder has been updated', 'success')
        }
      })
      currentEditFolderId.value = undefined
    }
    const removeFolder = (node: VariableNodeInterface) => {
      deleteEditorVariableNode(node.id)
    }

    const handleEditClick = () => (editMode.value = !editMode.value)
    const isCurrentEditFolder = (id: number) => currentEditFolderId.value === id
    const setCurrentEditFolder = (id?: number) => {
      currentEditFolderId.value = id
    }
    const isNodeRemoveable = (node: VariableNodeInterface) => {
      return editMode.value && node.folder && node.children.length === 0
    }
    const nodeClickedHandler = (
      tree: BaseTree.Tree,
      node: VariableNodeInterface
    ) => {
      if (node.folder) {
        tree.toggleFold(node)
        return
      }

      if (!editMode.value) {
        emit('variableClicked', node)
      }
    }

    const dropChangeHandler = (store: Draggable.Store3) => {
      const id = store.draggingNode.id
      const parentId = store.targetPath.parent?.id || null
      const order = store.targetPath.index
      updateEditorVariableNode({
        data: { id, parentId, order }
      })
    }

    const searchTriggeredHandler = (e: Event) => {
      const query = (<HTMLTextAreaElement>e.target).value ?? ''
      filterEditorVariableNodes({ kind: kind.value, query })
    }

    const childrenLoadHandler = (node: VariableNodeInterface) => {
      return loadEditorVariableChildren(node)
    }

    return () =>
      slots.default!({
        editMode: editMode.value,
        treeData: treeData.value,
        isNodeRemoveable,
        createFolderHandler,
        updateFolderNameHandler,
        removeFolder,
        handleEditClick,
        isCurrentEditFolder,
        setCurrentEditFolder,
        nodeClickedHandler,
        dropChangeHandler,
        searchTriggeredHandler,
        childrenLoadHandler
      })
  }
})
