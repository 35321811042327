import { Module } from 'vuex'
import * as consts from '@/utils/constants'
import actions from '@/store/preview/actions'
import mutations from '@/store/preview/mutations'
import getters from '@/store/preview/getters'
import { clearedPreviewFields } from '@/store/preview/helpers/usePreviewHelpers'
import { PreviewStateInterface } from '@/models/store/PreviewState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const previewModule: Module<PreviewStateInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    ...clearedPreviewFields(),
    environment: consts.DEFAULT_PAIR_ENVIRONMENT,
    loading: false,
    loadingTargetData: false,
    downloadingPdf: false,
    error: null,
    targetId: undefined,
    targetType: undefined
  } as PreviewStateInterface,
  actions,
  mutations,
  getters
}

export default previewModule
