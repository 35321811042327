<template>
  <div class="base-table uk-overflow-auto uk-padding-small">
    <table class="uk-table uk-table-striped">
      <thead>
        <slot name="tableHead" />
      </thead>
      <tbody>
        <slot name="tableBody" />
      </tbody>
    </table>
  </div>
</template>
