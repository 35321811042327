import * as consts from '@/utils/constants'
import { TemplatesStateInterface } from '@/models/store/TemplatesState.interface'
import { TemplateInterface } from '@/models/templates/Template.interface'
import { TemplateVariant } from '@/models/templates/VariantsInfo.interface'

const defaultTemplateData = () => {
  return {
    body: '{% content %}',
    changed: false,
    tags: [],
    versions: [],
    attachmentsMetadata: [],
    target: consts.DEFAULT_TEMPLATE_TARGET
  } as TemplateInterface
}

const defaultVariantsInfo = () => {
  const defaultTemplateVariant = {
    language: consts.DEFAULT_LANGUAGE,
    tonality: consts.DEFAULT_TONALITY,
    country: consts.DEFAULT_COUNTRY
  }

  return {
    available: [{ ...defaultTemplateVariant }],
    new: [{ ...defaultTemplateVariant }],
    loading: true
  }
}

const isNewTemplateVariant = (
  state: TemplatesStateInterface,
  templateVariant: TemplateVariant
) => {
  const { language, tonality, country } = templateVariant
  const isIncluded = ({ language: l, tonality: t, country: c }) =>
    l === language && t === tonality && c === country

  return state.variantsInfo.new.some(isIncluded)
}

const setTemplateVariantToTemplate = (state: TemplatesStateInterface): void => {
  const templateVariant = state.variantsInfo.available[0]
  state.template = { ...state.template, ...templateVariant }
}

const pickDefaultTemplateVariant = (
  templateVariants: TemplateVariant[]
): TemplateVariant => {
  return { ...templateVariants[0] } as TemplateVariant
}

const addTemplateVariant = (
  state: TemplatesStateInterface,
  type: 'new' | 'available',
  templateVariant: TemplateVariant
): void => {
  state.variantsInfo[type].push(templateVariant)
}

const removeTemplateVariant = (
  state: TemplatesStateInterface,
  type: 'new' | 'available',
  templateVariant: TemplateVariant
): void => {
  const { language, tonality, country } = templateVariant

  state.variantsInfo[type] = state.variantsInfo[type].filter(
    ({ language: l, tonality: t, country: c }) => {
      return !(language === l && tonality === t && country === c)
    }
  )
}

export {
  defaultTemplateData,
  defaultVariantsInfo,
  isNewTemplateVariant,
  setTemplateVariantToTemplate,
  pickDefaultTemplateVariant,
  addTemplateVariant,
  removeTemplateVariant
}
