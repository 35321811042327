import { VariableNodeInterface } from '@/models/tree/VariableNode.interface'

const snakeToCamel = (s: string) => {
  return s.replace(/(_\w)/g, (k) => k[1].toUpperCase())
}

type IMapValueType = string | number | boolean
interface IMap<T> {
  [index: string]: T
  [index: number]: T
}

export const convertSnakeToCamel = (obj: IMap<IMapValueType>) => {
  const newObj: IMap<IMapValueType> = { ...obj }
  const keys: string[] = Object.keys(obj)

  keys.forEach((key) => {
    newObj[snakeToCamel(key)] = obj[key]
  })
  return newObj
}

export type FromValueInfo = {
  fromText?: string | null
  fromAddress?: string | null
}
export const parseFromValue = (value: string): FromValueInfo => {
  const regExp = /^(?<fromText>[^<|.]+)?(<+(?<fromAddress>.+)?>+)*/
  const match: RegExpMatchArray | null = value.match(regExp)

  if (match) {
    const groups: { [p: string]: string } | undefined = match.groups
    const info: FromValueInfo = groups as FromValueInfo
    info.fromText = info.fromText?.trim() || null
    info.fromAddress = info.fromAddress?.trim() || null
    info.fromAddress = info.fromAddress === 'null' ? null : info.fromAddress

    return info
  }
  return { fromText: null, fromAddress: null }
}
export const buildFromValue = (text: string, email: string): string | null => {
  if (text || email) {
    if (!email) {
      return text.trim()
    } else {
      return `${text} <${email}>`.trim()
    }
  }

  return null
}

const treeKindToTypeMapping: IMap<string> = {
  snippets: 'snippet',
  images: 'image',
  links: 'calendar_link'
}
export const mapTreeKindToType = (treeKind: string): string => {
  return treeKindToTypeMapping[treeKind] || treeKind
}

export const sortVariableNodes = ({
  nodes,
  foldersFirst
}: {
  nodes: VariableNodeInterface[]
  foldersFirst?: boolean
}): VariableNodeInterface[] => {
  foldersFirst = typeof foldersFirst === 'undefined' ? false : foldersFirst

  return nodes.sort((a, b) => {
    if (foldersFirst) {
      if (a.folder && !b.folder) return -1
      if (b.folder && !a.folder) return 1
    }

    if (typeof a.order === 'undefined' && typeof b.order === 'undefined')
      return a.name.localeCompare(b.name)

    return (a?.order ?? 0) - (b?.order ?? 0)
  })
}

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const transformKeys = (
  object: object,
  transformFunc: (string) => string
) => {
  return Object.fromEntries(
    Object.entries(object).map(([k, v]) => [transformFunc(k), v])
  )
}

export const diff = (arr1: string[], arr2: string[]) =>
  arr1.filter((el) => !arr2.includes(el))

export const shortenString = (str: string | undefined, maxLength = 50) => {
  if (!str) return str
  if (str.length <= maxLength) return str

  return str.substring(0, maxLength) + '...'
}
