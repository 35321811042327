import UIkit from 'uikit'

export const useModalConfirm = (
  message: string,
  confirmedCallback: () => void,
  rejectedCallback: () => void
) => {
  UIkit.modal.confirm(message).then(
    () => confirmedCallback(),
    () => rejectedCallback()
  )
}

export const useModalPrompt = (
  content: string,
  value: string,
  confirmedCallback: (name: string) => void
) => {
  UIkit.modal.prompt(content, value).then((name) => {
    if (name) {
      confirmedCallback(name)
    }
  })
}
