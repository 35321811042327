import { ActionTree } from 'vuex'
import { fetchImages, deleteImage } from '@/api_client'
import { ImagesStateInterface } from '@/models/store/ImagesState.interface'
import { ImageInterface } from '@/models/images/Image.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

const actions: ActionTree<ImagesStateInterface, RootStateInterface> = {
  async loadImages({ commit, rootState, dispatch }) {
    commit('loadingImages', { setLoadingFlag: false })

    const { page, perPage } = rootState.pagination
    const { data, totalCount } = await fetchImages({ page, perPage })

    commit('loadedImages', data as ImageInterface[])
    dispatch('pagination/updateTotalCount', totalCount, { root: true })
  },
  async destroyImage(context, id: number) {
    await deleteImage(id)
  },
  uploadedImage({ commit }, image: ImageInterface) {
    commit('uploadedImage', image)
  }
}

export default actions
