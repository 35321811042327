import { Module } from 'vuex'
import actions from '@/store/tree/actions'
import mutations from '@/store/tree/mutations'
import getters from '@/store/tree/getters'
import { TreeStateInterface } from '@/models/store/TreeState.interface'
import { RootStateInterface } from '@/models/store/RootState.interface'

export const initState = () => {
  return {
    treeView: false,
    tree: [],
    loading: true,
    kind: 'variables'
  } as TreeStateInterface
}

const treeModule: Module<TreeStateInterface, RootStateInterface> = {
  namespaced: true,
  state: initState(),
  actions,
  mutations,
  getters
}

export default treeModule
