import {
  createI18n,
  LocaleMessages,
  VueMessageType,
  LocaleMessageDictionary
} from 'vue-i18n'

function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  return process.env.NODE_ENV !== 'test'
    ? loadLocaleMessagesForProd()
    : loadLocaleMessagesForTesting()
}

function loadLocaleMessagesForProd(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function loadLocaleMessagesForTesting(): LocaleMessages<VueMessageType> {
  const locales: { [key: string]: LocaleMessageDictionary<VueMessageType> } = {
    en: require('./locales/en')
  }
  const messages: LocaleMessages<VueMessageType> = {}
  Object.keys(locales).forEach((key) => {
    messages[key] = locales[key]
  })

  return messages
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
